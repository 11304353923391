<!-- TODO: dynamic switching mechanism -->
<div
  id="header"
  class="header"
>
  <img
    class="logo"
    data-test="header-logo"
    src="/assets/images/paypo.svg"
    alt="{{'PRODUCT_NAME' | translate}}"
  >
</div>
